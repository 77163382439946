import {sendPost, sendGet, sendPut} from './base';

export const login = async (data) => {
  return await sendPost(`/auth/signin/`, {body: data, noAuthRedirect: true})
    .catch(e => {
      throw e.statusCode === 401 ? 'Login failed' : e;
    });
};

export const logout = async () => {
  return await sendGet(`/auth/logout/`)
    .catch(e => {
      throw e.statusCode === 401 ? 'Logout failed' : e;
    });
};

export const checkLogin = async () => {
  return sendGet(`/auth/`, {noAuthRedirect: true});
};

export const changePassword = async (values) => {
  return sendPut('/auth/password', { body: values })
    .catch(e => {
      throw e.statusCode === 401 ? 'Logout failed' : e;
    });
}