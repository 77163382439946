import * as _ from 'lodash';
import _download from 'downloadjs';
import queryString from 'query-string';

const baseUrl = '/api';


export async function sendGet(url, options) {
  [url, options] = formalizeOptions(url, options);
  const response = await fetch(baseUrl + url, {
    method: 'GET',
    ...options,
  });
  return handleResponse(response, options);
}

export async function sendDelete(url, options) {
  [url, options] = formalizeOptions(url, options);
  const response = await fetch(baseUrl + url, {
    method: 'DELETE',
    ...options,
  });
  return handleResponse(response, options);
}

export async function sendPost(url, options) {
  [url, options] = formalizeOptions(url, options);
  const response = await fetch(baseUrl + url, {
    method: 'POST',
    ...options,
  });
  return handleResponse(response, options);
}

export async function sendPut(url, options) {
  [url, options] = formalizeOptions(url, options);
  const response = await fetch(baseUrl + url, {
    method: 'PUT',
    ...options,
  });
  return handleResponse(response, options);
}

export async function download(url, {file,...options }) {
  [url, options] = formalizeOptions(url, options);
  return fetch(baseUrl + url, {
    method: 'GET',
    ...options,
  })
    .then(async response => {
      if (response.ok) {
        return response.blob();
      } else {
        throw new Error(await response.text());
      }
    })
    .then((blob) => _download(blob, file.name, file.mimeType));
}

function formalizeOptions(url, {queryParams, ...options} = {}) {
  if(queryParams) {
    url += (url.indexOf('?') === -1 ? '?' : '&') + queryString.stringify(queryParams, {arrayFormat: 'bracket'});
  }
  if (options.body) {
    options.body = JSON.stringify(options.body);
    options.headers = options.headers || {};
    options.headers['Content-Type'] = options.headers['Content-Type'] || 'application/json';
  }
  return [url, options];
}

async function handleResponse(response, options) {
  if (!response.ok) {
    if (response.status === 403) {
      window.location.hash = '/#403';
      throw new Error('Forbidden');
    }
    if (response.status === 401 && !options.noAuthRedirect) {
      window.location.hash = '#login';
      window.history.go(0);
      throw new Error('Unauthorized');
    }

    const error = await response.json();

    if (error.invalidFields) {
      throw _.flatten(_.values(error.invalidFields).map(_.values));
    } else if (error.statusCode) {
      // eslint-disable-next-line
      throw [error.error || error.message];
    } else {
      throw _.isArray(error) ? error : [error];
    }
  }
  const text = await response.text();
  return text.length ? JSON.parse(text) : null;
}