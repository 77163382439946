import React, { useContext, useState } from 'react';
import usePromise from 'react-promise-suspense';
import { HashRouter, Route, Redirect, useLocation, Switch } from 'react-router-dom';
import './App.scss';
import { checkLogin } from './api/auth';
import { AuthContext } from './context';

const loading = () => <div className="animated fadeIn pt-3 text-center">載入中...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
const Page403 = React.lazy(() => import('./views/Pages/Page403'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));

function useQueryParam(name) {
  const query = new URLSearchParams(useLocation().search);
  return query.get(name);
}

function RedirectToLoginPage () {
  const location = useLocation();
  console.log('RedirectToLoginPage');
  return (
    <Redirect to={{
      pathname:`/login`,
      search: location.pathname !== '/' ? `?redirect=${location.pathname}` : undefined,
    }} />
  );
}

const Authenticator = ({children}) => {
  const user = usePromise(() => checkLogin().catch(() => {}), []);
  const [account, setAccount] = useState(user);
  return (
    <AuthContext.Provider value={{ account, setAccount: a => console.log(a) || setAccount(a) }}>
      {children}
    </AuthContext.Provider>
  )
}

const ParamRedirect = () => {
  const redirect = useQueryParam('redirect');
  return <Redirect to={redirect || '/'} />;
}

const Router = () => {
  const { account: user } = useContext(AuthContext);
  return (
    <HashRouter>
      <Switch>
        <Route exact path="/500" name="Page 500" render={(...props) => <Page500 {...props} />} />
        <Route exact path="/403" name="Page 403" render={(...props) => <Page403 {...props} />} />
        <Route exact path="/404" name="Page 404" render={(...props) => <Page404 {...props} />} />
        <Route exact path="/login" name="Login Page" render={(...props) => !user ? <Login {...props} /> : <ParamRedirect />} />
        <Route path="/" name="Home" render={(...props) => user ? <DefaultLayout {...props} /> : <RedirectToLoginPage />} />
      </Switch>
    </HashRouter>
  )
}

export default () => {
  return (
    <React.Suspense fallback={loading()}>
      <Authenticator>
        <Router />
      </Authenticator>
    </React.Suspense>
  );
}
